.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh-loader);
  background: rgba(0, 0, 0, 1);
  z-index: 1000000000;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: 1s cubic-bezier(0.66, 0.05, 0.08, 0.97);
  transition-delay: 1s;

  .loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      margin-right: 30rem;
      transition: 0.7s ease-in-out;
      width: 200rem;
      height: 200rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 768px) {
        margin-right: 20px;
        width: 200px;
        height: 200px;
      }
    }
  }

  .loader-icon-first {
    opacity: 0;
  }
}

.loader-text {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 60rem;
  font-weight: bold;

  overflow: hidden;
  span {
    display: block;
    transition: 1s cubic-bezier(0.66, 0.05, 0.08, 0.97);
    transition-delay: 0.5s;
  }

  @media (max-width: 1100px) {
    font-size: 30px;
  }
}

.loader-hidden {
  transform: translateY(-100%);

  svg {
    opacity: 0;
  }

  .loader-text {
    span {
      transform: translateY(-100%);
    }
  }
}
