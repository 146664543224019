.character {
  font-size: 21rem;
  padding-bottom: 165rem;
  position: relative;
  background: var(--black);
  z-index: 105;

  @media (max-width: 768px) {
    padding-bottom: 0;
    font-size: 18px;
    height: auto;
  }

  @media (max-width: 770px) and (min-height: 900px) {
    padding-top: 100px;
    height: auto;
  }

  @media (max-width: 768px) and (min-height: 650px) {
    height: auto;
    margin-top: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 80%;
    height: 90%;
    background-color: #351007;
    filter: blur(80rem);
    transform: translate3d(0, 0, 0);
    z-index: -1;

    @media (max-width: 1100px) {
      filter: blur(100px);
      height: 80%;
      width: 80%;
    }

    @media (max-width: 770px) and (min-height: 900px) {
      display: none;
    }

    @media (max-width: 1000px) and (min-height: 1000px) {
      display: none;
    }
  }

  h2 {
    margin-bottom: 80rem;

    @media (max-width: 1100px) {
      margin-bottom: 50px;
    }

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .character-block {
    width: 47.5%;
    margin-bottom: 80rem;
    transform: translateY(10%);
    opacity: 0;

    @media (max-width: 1100px) {
      margin-bottom: 60px;
      width: 45%;
    }

    @media (max-width: 768px) {
      margin-bottom: 60px;
      width: 100%;

      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(3) {
        order: 3;
      }
      &:nth-child(4) {
        order: 4;
      }
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 20rem;
      object-fit: cover;

      @media (max-width: 768px) {
        height: 100%;
        border-radius: 25px;
      }
    }

    h3 {
      margin-top: 20rem;

      @media (max-width: 1100px) {
        margin-top: 10px;
      }

      @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 20rem;
      font-weight: 400;
      margin-top: 24rem;
      width: 90%;
      opacity: 0.8;

      @media (max-width: 1100px) {
        margin-top: 10px;
        font-size: 16px;
        width: 100%;
      }
    }
  }
}
