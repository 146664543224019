@font-face {
  font-family: 'Mulish';
  src: url('../../public/fonts/Mulish-SemiBold.woff2') format('woff2'),
    url('../../public/fonts/Mulish-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../public/fonts/Mulish-Regular.woff2') format('woff2'),
    url('../../public/fonts/Mulish-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../public/fonts/Mulish-Medium.woff2') format('woff2'),
    url('../../public/fonts/Mulish-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../public/fonts/Mulish-Bold.woff2') format('woff2'),
    url('../../public/fonts/Mulish-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Mulish';
  src: url('../../public/fonts/Mulish-Black.woff2') format('woff2'),
    url('../../public/fonts/Mulish-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
