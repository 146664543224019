.footer {
  padding: 32rem 0;
  background-color: var(--accent-bg-color);
  color: var(--brown);
  position: relative;
  z-index: 101;
  overflow: hidden;

  @media (max-width: 1100px) {
    padding: 20px 0;
  }

  .footer-icon {
    position: absolute;
    bottom: -45%;
    left: 100rem;
    transform: rotate(90deg) translateX(-50%);
    transform-origin: center;
    width: 30rem;
    height: 100%;

    @media (max-width: 1100px) {
      width: 25px;
      left: 30px;
    }

    @media (max-width: 768px) {
      width: 12px;
      left: 25px;
      bottom: -47%;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  &-copy {
    font-size: 14rem;
    font-weight: 500;

    @media (max-width: 1100px) {
      font-size: 10px;
    }

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 8rem;

    @media (max-width: 1100px) {
      gap: 10px;
      justify-content: center;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 8rem;
      background: #bd421b29;
      padding: 8rem;
      border-radius: 16rem;

      @media (max-width: 1100px) {
        padding: 10px;
        border-radius: 12px;
        gap: 10px;
        justify-content: center;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      li {
        height: 36rem;

        @media (max-width: 1100px) {
          height: unset;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 16rem;
          font-weight: 500;
          position: relative;
          overflow: hidden;
          display: inline-block;
          background-color: #bd421b8a;
          color: var(--white);
          padding: 8rem 16rem;
          border-radius: 10rem;
          transition: 0.3s ease;

          @media (max-width: 1100px) {
            font-size: 14px;
            border-radius: 8px;
            padding: 5px 10px;
            min-width: 130px;
            text-align: center;
          }

          &:hover {
            background-color: #d43b0cc5;
          }
        }
      }
    }

    .language-selector {
      background: #bd421b29;
      padding: 8rem;
      border-radius: 16rem;

      @media (max-width: 1100px) {
        padding: 10px;
        border-radius: 12px;
        gap: 10px;
      }

      button {
        border-radius: 10rem;
        padding: 8rem 16rem;
        font-size: 16rem;
        font-weight: 500;
        color: var(--white);
        background-color: #bd421b8a;
        transition: 0.3s ease;
        cursor: pointer;

        @media (max-width: 1100px) {
          font-size: 14px;
          padding: 5px 10px;
          border-radius: 8px;
        }

        &:hover {
          background-color: #d43b0cc5;
        }
      }
    }
  }
}
