.big-identity {
  height: calc(320vh - 10vh);
  background-color: var(--black);

  @media (min-height: 700px) and (max-height: 1300px) and (min-width: 1000px) and (max-width: 1300px) {
    height: 220vh;
  }

  @media (max-width: 769px) and (min-height: 1000px) {
    margin-top: 250px;
  }

  @media (max-width: 1300px) and (min-width: 991px) and (min-height: 790px) {
    height: 300vh;
  }

  @media (max-width: 1350px) and (min-width: 991px) and (min-height: 790px) and (max-height: 1200px) {
    height: 220vh;
  }

  @media (max-width: 1300px) and (min-height: 700px) {
    height: 220vh;
  }

  @media (max-width: 1300px) and (min-height: 800px) {
    height: 250vh;
  }

  @media (max-width: 1300px) and (min-height: 900px) {
    height: 210vh;
  }

  @media (max-width: 1300px) and (min-height: 1000px) {
    height: 190vh;
  }

  @media (max-width: 1300px) and (min-height: 1100px) {
    height: 185vh;
  }

  @media (max-width: 1100px) {
    height: unset;
  }

  h2 {
    text-align: center;
    margin-top: 100rem;
    margin-bottom: 30rem;

    @media (max-width: 1100px) {
      margin-top: 0;
      margin-bottom: 30px;
      padding: 0 15px;
    }

    @media (max-width: 1100px) {
      margin-top: 100px;
    }

    @media (max-width: 1150px) and (min-width: 991px) and (min-height: 550px) {
      margin-top: 450rem;
    }

    @media (max-width: 991px) and (min-height: 800px) and (min-width: 768px) {
      margin-top: 350px;
    }
  }

  h3 {
    text-align: center;

    @media (max-width: 1100px) {
      font-size: 18px;
      padding: 0 15px;
    }
  }

  .big-identity-wrap {
    height: 200vh;

    @media (max-width: 1100px) {
      height: unset;
    }
  }
}

.grid-wrapper {
  height: var(--vh);

  @media (max-width: 1100px) {
    height: unset;
    margin-top: 50px;
  }
}

.grid {
  display: grid;
  place-items: center;
  padding: 2rem;
  width: 100%;
  perspective: 1500px;
  transform: translateY(-28%);

  @media (max-width: 1100px) {
    transform: unset;
    display: unset;
  }
}

.grid-wrap {
  height: auto;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  gap: 2vw;
  transform-style: preserve-3d;
  transform: translateX(-16%);
  margin-bottom: 1150rem;

  @media (min-width: 2400px) {
    margin-bottom: 250rem;
  }

  @media (min-width: 1610px) {
    margin-bottom: 1250rem;
  }

  @media (max-width: 1610px) {
    margin-bottom: 1900rem;
  }

  @media (max-width: 1400px) {
    margin-bottom: 0;
  }

  @media (max-width: 1380px) {
    margin-bottom: 3500rem;
  }

  @media (max-width: 1100px) {
    margin-bottom: 0;
    gap: unset;
    display: unset;
  }
}

.grid__item {
  aspect-ratio: 1.5;
  width: 700rem;
  height: 700rem;
  overflow: hidden;
  position: relative;
  display: grid;
  place-items: center;

  @media (min-width: 1100px) {
    transform: translateZ(-5000rem);
    opacity: 0;

    &:nth-child(1) {
      transform: translateX(-300rem) translateZ(-5000rem);
    }

    &:nth-child(2) {
      transform: translateY(-200rem) translateX(100rem) translateZ(-5000rem);
    }

    &:nth-child(3) {
      transform: translateX(300rem) translateZ(-5000rem);
    }

    &:nth-child(4) {
      transform: translateX(-400rem) translateY(300rem) translateZ(-5000rem);
    }
    &:nth-child(6) {
      transform: translateX(500rem) translateY(150rem) translateZ(-5000rem);
    }
  }

  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
    transform: unset;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grid__item-inner {
  position: relative;
  width: 100%;
  height: 500rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (max-width: 1100px) {
    height: 100%;
    background-size: cover;
  }
}
