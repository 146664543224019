:root {
  --white: #ffffff;
  --brown: #6b3a2d;
  --black: #010101;
  --grey: #b4b4b4;
  --red: #ad0000;
  --orange: #c04722;
  --svg: #df3c17;
  --bg-color: #2f1108;
  --accent-bg-color: #efeee4;
  --text-color: #efeee4;
  --text-title-black-color: #100c08;
  --h4-title-color: #efeee4;
  --download-text: #000000;
  --dark-bg-color: #1e0904;

  --btn: #bd421b;
  --transition: all 0.5s cubic-bezier(0.66, 0.05, 0.08, 0.97);

  --vh: 100vh;
  --vh-loader: 100vh;
}

@supports (height: 100dvh) {
  :root {
    --vh: 100vh;
  }
}

@supports (height: 100dvh) {
  :root {
    --vh-loader: 100dvh;
  }
}

::selection {
  background: transparent;
  color: var(--red);
}

html {
  overflow-x: hidden;
}

body {
  font-family: 'Mulish';
  background-color: var(--black);
  color: var(--text-color);
  overflow-x: hidden;
}

.container {
  max-width: 1470rem;
  margin: 0 auto;
  padding: 0 15rem;
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
a,
li {
  font-size: 20rem;
}

h2 {
  font-size: 48rem;
  font-weight: 800;
  margin-bottom: 48rem;
}

h3 {
  font-size: 34rem;
  font-weight: 700;
}

h4 {
  font-size: 24rem;
  font-weight: 600;
  color: var(--h4-title-color);
}

section {
  position: relative;
  z-index: 100;
}

.canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: 100;
}

.blur {
  position: absolute;
  background-color: #351007;
  filter: blur(200rem);
  transform: translate3d(0, 0, 0);
  z-index: -1;
}

.blur-canva {
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
}

.blur-research {
  top: 150%;
  left: 0;
  width: 60%;
  height: 150%;
}

.blur-symbols {
  top: 300%;
  left: 0;
  height: 100%;
  width: 100%;
}

#leva__root {
  display: none;
}

@media (max-width: 1100px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .blur {
    filter: blur(80px);
  }

  .blur-canva {
    height: 50%;
    top: 50%;
  }

  .blur-research {
    height: 220%;
    width: 80%;
  }

  .blur-symbols {
    display: none;
  }

  h2 {
    font-size: 48px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 34px;
  }

  h4 {
    font-size: 24px;
  }

  p,
  h1,
  a,
  li {
    font-size: 5px;
  }
}

@media (max-width: 768px) {
  .canvas {
    z-index: -1;
  }
}
