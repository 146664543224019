.video {
  height: var(--vh);
  width: 100%;
  position: relative;
  z-index: 101;
  cursor: pointer;

  @media (max-width: 768px) {
    height: auto;
  }

  &-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.932);
    z-index: 10000000;
    opacity: 0;
    pointer-events: none;

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      height: 70%;
      overflow: hidden;

      @media (max-width: 991px) {
        width: 90%;
        height: 40%;
      }
    }
  }

  .poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
  }

  .play {
    width: 150rem;
    height: 150rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: transform 0.5s;

    &:hover {
      transform: translate(-50%, -50%) scale(1.2);
    }

    @media (max-width: 1100px) {
      width: 50px;
      height: 50px;
    }

    img {
      width: 100%;
      height: 100%;

      @media (max-width: 1100px) {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.team {
  padding: 100rem 0;
  background-color: var(--accent-bg-color);
  position: relative;
  z-index: 101;

  @media (max-width: 1100px) {
    padding: 50px 0;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1100px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  h2,
  h4 {
    color: var(--text-title-black-color);
    font-weight: 700;

    span {
      font-weight: 400;
    }
  }

  h2 {
    margin-bottom: 60rem;

    @media (max-width: 1100px) {
      margin-bottom: 30px;
      text-align: center;
    }

    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  h4 {
    margin-bottom: 32rem;

    @media (max-width: 1100px) {
      margin-bottom: 15px;
    }
  }

  .team-block {
    width: 50%;

    @media (max-width: 1100px) {
      width: 100%;
    }

    &:first-child {
      h4 {
        font-weight: 800;
        width: 40%;

        @media (max-width: 1100px) {
          width: 100%;
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }

    .address {
      margin-bottom: 32rem;
      display: flex;
      flex-direction: column;

      @media (max-width: 1100px) {
        margin-bottom: 20px;
      }

      li {
        display: flex;
        width: 100%;

        @media (max-width: 1100px) {
          justify-content: flex-start;
          flex-direction: column;
        }

        h5 {
          min-width: 80rem;

          @media (max-width: 1100px) {
            min-width: max-content;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }

        p {
          margin-left: 68rem;
          text-align: left;

          @media (max-width: 1100px) {
            margin-left: 0;
            text-align: center;
          }
        }
      }
    }

    &:last-child {
      @media (max-width: 1100px) {
        margin-top: 50px;
      }
      h4 {
        width: 70%;
        font-weight: 800;

        span {
          display: block;
        }

        @media (max-width: 1100px) {
          width: 100%;
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 1100px) {
        justify-content: center;
      }

      li {
        margin-bottom: 16rem;
        color: #333333;
        font-size: 16rem;
        font-weight: 600;
        width: 50%;

        @media (max-width: 1100px) {
          margin-bottom: 15px;
          font-size: 18px;
          text-align: center;
        }

        @media (max-width: 768px) {
          margin-bottom: 25px;
        }

        @media (max-width: 768px) {
          font-size: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }
        h5 {
          color: #333333;
          font-size: 16rem;
          font-weight: 600;
          margin-bottom: 5rem;

          @media (max-width: 1100px) {
            margin-bottom: 5px;
            font-size: 18px;
          }

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        p {
          font-size: 16rem;
          font-weight: 500;
          color: #666666;

          @media (max-width: 1100px) {
            font-size: 14px;
          }

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .team-moderator {
    margin-top: 60rem;

    @media (max-width: 1100px) {
      margin-top: 50px;
    }

    h3 {
      font-size: 24rem;
      font-weight: 500;
      color: var(--text-title-black-color);
      width: 20%;
      margin-bottom: 32rem;

      @media (max-width: 1100px) {
        font-size: 18px;
        width: 100%;
        text-align: center;
      }

      span {
        font-weight: 800;
      }
    }

    div {
      h5 {
        color: #333333;
        font-size: 16rem;
        font-weight: 600;
        margin-bottom: 4rem;

        @media (max-width: 1100px) {
          font-size: 18px;
          text-align: center;
        }
      }
      p {
        font-size: 16rem;
        font-weight: 500;
        color: #666666;

        @media (max-width: 1100px) {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}

.download {
  padding: 100rem 0;
  position: relative;
  z-index: 101;
  background-color: var(--dark-bg-color);

  @media (max-width: 1100px) {
    padding: 50px 0;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32rem;

    @media (max-width: 1100px) {
      gap: unset;
    }
  }

  h3 {
    margin-bottom: 60rem;

    @media (max-width: 1100px) {
      font-size: 38px;
      margin-bottom: 30px;
    }
  }

  .download-block {
    display: flex;
    align-items: baseline;
    position: relative;
    width: 46.5%;

    @media (max-width: 1100px) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:hover {
      .first-arrow {
        top: 4rem;
      }
      .second-arrow {
        transform: translateY(120%);
      }
      .download-btn__arrow:after {
        left: 100%;
        transition-delay: 0s;
      }
      .download-text {
        background-color: #c4c3bf;
      }
    }
  }

  &-text {
    display: flex;
    align-items: center;
    background-color: var(--accent-bg-color);
    color: var(--download-text);
    transition: var(--transition);
    font-size: 20rem;
    font-weight: 500;
    width: 100%;
    padding: 16rem 24rem;
    border-top-left-radius: 16rem;
    border-bottom-left-radius: 16rem;

    @media (max-width: 1100px) {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      font-size: 18px;
      padding: 15px;
    }

    @media (max-width: 768px) {
      padding: 10px 70px 10px 10px;

      .download-text__file {
        width: 15px;
        height: 15px;
      }
    }

    .download-text__icon {
      width: 19rem;
      height: 41rem;
      position: absolute;
      top: 50%;
      right: 8rem;
      transform: translateY(-50%);

      @media (max-width: 1100px) {
        width: 19px;
        height: 41px;
        right: 55px;
      }

      @media (max-width: 768px) {
        width: 15px;
        height: 25px;
      }
    }

    p {
      margin-left: 16rem;

      @media (max-width: 1100px) {
        font-size: 16px;
        margin-left: 10px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }

  &-btn {
    background-color: var(--btn);
    // padding: 12px 24rem;
    position: absolute;
    top: 0;
    right: -74rem;
    width: 73rem;
    height: 100%;
    border-top-right-radius: 16rem;
    border-bottom-right-radius: 16rem;

    @media (max-width: 1100px) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      right: 0;
      width: 50px;
    }

    &__arrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        bottom: 3rem;
        left: 0;
        width: 100%;
        height: 3rem;
        border-radius: 20rem;
        background-color: var(--accent-bg-color);
        transition: var(--transition);
        transition-delay: 0.5s;
        // display: none;

        @media (max-width: 1100px) {
          bottom: 0;
          height: 2px;
          border-radius: unset;
        }
      }

      .first-arrow {
        position: absolute;
        top: -100%;
        left: 0;
        transition: var(--transition);
        transition-delay: 0.3s;
      }

      .second-arrow {
        transition: var(--transition);
        transition-delay: 0.3s;
        path {
          transform: translateX(0.7rem);
        }
      }
    }
  }
}

.partners {
  background-color: var(--accent-bg-color);
  padding: 48rem 0;
  position: relative;
  z-index: 101;

  @media (max-width: 1100px) {
    padding: 30px 0;
  }

  img {
    width: 100%;
    height: 100%;

    @media (max-width: 1100px) {
      width: 90%;
      margin: 0 auto;
      display: block;
      object-fit: contain;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  p {
    color: var(--text-title-black-color);
    margin-top: 32rem;
    font-size: 12rem;
    font-weight: 500;

    @media (max-width: 1100px) {
      margin-top: 20px;
      font-size: 10px;
    }
  }
}

.create {
  padding: 100rem 0;
  background-color: var(--dark-bg-color);
  position: relative;
  z-index: 101;

  @media (max-width: 1100px) {
    padding: 50px 0;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  h2 {
    color: var(--h4-title-color);

    @media (max-width: 1100px) {
      font-size: 35px;
      margin-bottom: 30px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 550px) {
      flex-direction: column;
    }
  }

  &-block {
    &:first-child {
      width: 50%;

      @media (max-width: 550px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    &__button {
      font-weight: 700;
      font-size: 18rem;
      padding: 16rem 28rem;
      background-color: var(--btn);
      width: max-content;
      border-radius: 15rem;
      cursor: pointer;
      transition: 0.4s ease;

      @media (max-width: 1100px) {
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 20px;
      }

      &:hover {
        background-color: #8d3013;
      }
    }
  }
}

.contacts {
  margin-bottom: 41rem;

  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 24rem;

    @media (max-width: 1100px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  &-menu__item {
    margin-bottom: 24rem;

    @media (max-width: 1100px) {
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .contants-link {
    display: flex;
    align-items: center;

    .contacts-link__text {
      font-size: 16rem;
      font-weight: 500;
      margin-left: 16rem;
      color: var(--white);
      position: relative;
      overflow: hidden;
      display: inline-block;

      @media (max-width: 1100px) {
        font-size: 12px;
        margin-left: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1rem;
        background-color: var(--white);

        @media (max-width: 1100px) {
          height: 1px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 1rem;
        background-color: var(--white);
        transition: 0.5s cubic-bezier(0.61, 0.05, 0.25, 0.9);
        transition-delay: 0.3s;

        @media (max-width: 1100px) {
          height: 1px;
        }
      }
    }

    &:hover {
      .contacts-link__text {
        &:after {
          left: 100%;
          transition: 0.5s cubic-bezier(0.61, 0.05, 0.25, 0.9);
        }
        &:before {
          left: 0%;
        }
      }
    }

    .contacts-link__img {
      width: 32rem;
      height: 32rem;
      background-color: var(--accent-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10rem;

      @media (max-width: 1100px) {
        width: 25px;
        height: 25px;
        border-radius: 5px;
      }

      img {
        width: 18rem;

        @media (max-width: 1100px) {
          width: 13px;
        }
      }
    }
  }
}

.social {
  h4 {
    margin-bottom: 24rem;

    @media (max-width: 1100px) {
      margin-bottom: 20px;
    }
  }

  ul {
    display: flex;

    li {
      margin-right: 16rem;

      @media (max-width: 1100px) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .social-link {
    width: 32rem;
    height: 32rem;
    background-color: var(--accent-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    transition: 0.4s ease;

    @media (max-width: 1100px) {
      width: 25px;
      height: 25px;
      border-radius: 5px;
    }

    img {
      width: 18rem;

      @media (max-width: 1100px) {
        width: 13px;
      }
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

.video-modal-close {
  position: fixed;
  top: 20rem;
  right: 20rem;
  width: 40rem;
  height: 40rem;
  cursor: pointer;
  transition: transform 0.4s ease;
  transform-origin: center;
  will-change: transform;

  @media (max-width: 991px) {
    width: 30px;
    height: 30px;
    top: 15px;
    right: 15px;
  }

  &:hover {
    transform: rotate(90deg);
  }

  &::after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3rem;
    transform: translateY(-50%) rotate(45deg);
    background-color: var(--orange);

    @media (max-width: 991px) {
      height: 2px;
    }
  }

  &:before {
    left: -1rem;
    transform: translateY(-50%) rotate(-45deg);
  }
}
