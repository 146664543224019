.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 22rem 0;
  backdrop-filter: blur(10rem);
  z-index: 1000;
  transition: transform 0.5s ease;

  @media (max-width: 1100px) {
    padding: 10px 0;
    backdrop-filter: blur(10px);
  }

  .burger {
    display: none;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 13px;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      height: 2px;
      background-color: var(--white);
      transition: 0.5s ease;
    }
    &:before {
      content: '';
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      height: 2px;
      background-color: var(--white);
      transition: 0.5s ease;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  &-logo {
    width: 135rem;
    height: 62rem;

    @media (max-width: 1100px) {
      width: 70px;
      height: 30px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-nav {
    display: flex;
    position: relative;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      right: -100%;
      width: 40%;
      height: var(--vh);
      flex-direction: column;
      background: #000;
      justify-content: center;
      align-items: center;
      transition: 0.8s ease;
    }

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 400px) {
      width: 60%;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 10rem;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }

    ul {
      display: flex;
      align-items: center;
      padding: 10rem 10rem;
      background-color: var(--black);
      border-radius: 16rem;

      @media (max-width: 1100px) {
        padding: 5px;
        border-radius: 10px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
      }

      li {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 10rem;

        @media (max-width: 1100px) {
          margin-right: 10px;
        }

        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          a {
            background-color: var(--red);
          }
        }

        a {
          border-radius: 10rem;
          padding: 8rem 16rem;
          font-size: 16rem;
          font-weight: 500;

          transition: 0.4s ease;

          @media (max-width: 1100px) {
            border-radius: 5px;
            padding: 10px;
            font-size: 12px;
          }

          @media (max-width: 768px) {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }

    .language-selector {
      padding: 10rem 10rem;
      background-color: var(--black);
      border-radius: 16rem;

      @media (max-width: 1100px) {
        padding: 5px;
        border-radius: 10px;
      }

      button {
        border-radius: 10rem;
        padding: 8rem 16rem;
        font-size: 16rem;
        font-weight: 500;
        transition: 0.4s ease;
        cursor: pointer;

        @media (max-width: 1100px) {
          border-radius: 5px;
          padding: 10px;
          font-size: 12px;
        }

        @media (max-width: 768px) {
          font-size: 18px;
          font-weight: 600;
        }

        &:hover {
          background-color: var(--red);
        }
      }
    }
  }

  .burger-active {
    &:after {
      transform: rotate(-45deg);
      top: 17px;
      left: 20%;
    }
    &:before {
      transform: rotate(45deg);
      top: 17px;
      left: 20%;
    }
  }
}

@media (min-width: 768px) {
  .header-hidden {
    transform: translateY(-100%) !important;
  }
}

.menu-active {
  right: 0;
}

.active-link {
  background-color: var(--red);
}
