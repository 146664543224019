.banner {
  height: var(--vh);
  position: relative;
  margin-top: 150rem;

  @media (max-width: 1100px) {
    height: unset;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .bilbord {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 600px) {
      object-fit: contain;
    }
  }

  .banner-desc {
    height: 100%;
    width: 100%;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .banner-mob {
    display: none;

    @media (max-width: 1100px) {
      display: block;

      .banner-mob__item {
        overflow: hidden;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .banner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 600px) {
        object-fit: contain;
      }
    }

    .banner-first {
      clip-path: inset(0% 0 0% 0);
    }
    .banner-second {
      z-index: 2;
      clip-path: inset(100% 0 0% 0);
    }
    .banner-third {
      z-index: 3;
      clip-path: inset(100% 0 0% 0);
    }
  }
}

.identity {
  background-color: var(--black);
  padding: 200rem 0;

  img {
    width: 100%;
  }

  @media (max-width: 1100px) {
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 50px 0;
  }

  @media (max-width: 600px) {
    padding: 0 0 50px;
  }

  .identity-banner {
    margin-bottom: 30rem;
    overflow: hidden;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .identity-big {
    margin-bottom: 30rem;
    overflow: hidden;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .identity-block {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 30rem;
      margin-bottom: 30rem;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      div {
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media (max-width: 768px) {
        gap: 20px;
      }
    }

    &__big {
      margin-bottom: 30rem;
      overflow: hidden;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }
}
