.hero {
  height: var(--vh);
  width: 100%;
  position: relative;
  z-index: 103;

  @media (max-height: 650px) {
    margin-top: 130px;
  }

  @media (max-height: 350px) {
    margin-top: 180px;
  }

  @media (max-width: 991px) and (min-width: 768px) and (min-height: 1150px) {
    height: 95dvh;
  }

  @media (max-width: 500px) {
    padding-bottom: 50px;
  }

  &__wrapper {
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      justify-content: flex-end;
    }

    @media (max-width: 991px) and (min-width: 768px) and (min-height: 1150px) {
      justify-content: flex-end;
    }
  }

  &-partners {
    transform: translateY(130rem);
    width: 50%;

    @media (max-width: 1110px) {
      transform: translateY(50px);
    }

    @media (max-width: 768px) {
      width: 100%;
      transform: unset;
      margin: 40px 0 20px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    p {
      font-size: 12rem;
      font-weight: 500;
      line-height: 1.5;
      color: var(--grey);
      margin-top: 16rem;

      @media (max-width: 1110px) {
        font-size: 10px;
        line-height: 1.1;
        margin-top: 10px;
      }

      @media (max-width: 768px) {
        font-size: 8px;
      }
    }
  }

  &-title {
    width: 55%;

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 991px) and (min-width: 768px) and (min-height: 1150px) {
      width: 80%;
    }

    h1 {
      font-weight: 700;
      margin-bottom: 30rem;
      font-size: 90rem;
      line-height: 1.1;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: 1110px) {
        font-size: 55px;
        margin-bottom: 15px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 1.1;
      }
    }

    img {
      width: 300rem;

      @media (max-width: 1100px) {
        width: 200px;
      }
    }

    p {
      font-size: 20rem;
      margin-top: 48rem;
      opacity: 0.8;
      width: 80%;

      @media (max-width: 1110px) {
        font-size: 16px;
        width: 100%;
      }
    }
  }
}

.research {
  padding-top: 250rem;
  position: relative;

  @media (max-width: 1110px) {
    padding-top: 100px;
  }

  @media (max-width: 550px) {
    margin-bottom: 50px;
  }

  &__wrapper {
    width: 60%;

    @media (max-width: 1110px) {
      width: 100%;

      h2 {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 768px) {
      h2 {
        font-size: 38px;
      }
    }
  }

  .research-blocks {
    .research-block {
      border-radius: 24rem;
      padding: 32rem;
      background: #ffffff0d;
      margin-bottom: 24rem;
      width: 75%;
      display: flex;
      align-items: flex-start;

      @media (max-width: 1100px) {
        border-radius: 15px;
        padding: 20px;
        width: 100%;
        margin-bottom: 20px;
        background-color: rgba(107, 61, 45, 0.2);
        border: 1rem solid rgba(93, 35, 19, 0.6117647059);
        -webkit-backdrop-filter: blur(20rem);
        backdrop-filter: blur(20px);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__text {
        width: 100%;
      }

      &__img {
        margin-right: 24px;
        width: 40rem;
        height: 40rem;
        position: relative;

        @media (max-width: 1100px) {
          width: 50px;
          height: 30px;
          margin-right: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &-active {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }

      h3 {
        font-size: 24rem;
        margin-bottom: 10rem;
        font-weight: 600;

        @media (max-width: 1100px) {
          font-size: 21px;
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 20rem;
        font-weight: 500;
        opacity: 0.8;

        @media (max-width: 1100px) {
          font-size: 16px;
        }
      }
    }
  }

  .research-text {
    font-size: 20rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 50rem;
    width: 70%;
    opacity: 0.8;

    @media (max-width: 1110px) {
      font-size: 16px;
      width: 100%;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .numbers {
    margin-top: 130rem;

    @media (max-width: 1100px) {
      margin-top: 50px;
    }

    &__wrapper {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      @media (max-width: 1100px) {
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
      }

      @media (max-width: 768px) {
        gap: 10px;
      }

      @media (max-width: 550px) {
        justify-content: space-between;
      }
    }

    .numbers-block {
      text-align: center;
      padding: 12rem 36rem;
      border-radius: 32rem;
      background-color: #6b3d2d33;
      border: 1rem solid #5d23139c;
      backdrop-filter: blur(20rem);
      width: 320rem;

      @media (max-width: 1100px) {
        padding: 8px 24px;
        border-radius: 16px;
        border-width: 1px;
        width: 220px;
      }

      @media (max-width: 768px) {
        padding: 12px 36px;
        min-width: auto;
      }

      @media (max-width: 550px) {
        width: 100%;
        padding: 12px;
        width: 48%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      h3 {
        font-size: 20rem;
        font-weight: 400;
        opacity: 0.8;

        @media (max-width: 1100px) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }

      div {
        font-size: 48rem;
        font-weight: 700;

        @media (max-width: 1100px) {
          font-size: 28px;
        }
      }
    }
  }
}
