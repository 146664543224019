.symbols {
  position: relative;
  z-index: 100;

  @media (max-width: 900px) and (min-height: 900px) {
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    margin-bottom: -300px;
  }

  @media (max-width: 769px) and (min-height: 1020px) and (min-width: 550px) {
    margin-bottom: auto;
  }

  &__wrapper {
    height: 100%;
  }
}

.symbols-title {
  text-align: center;
  color: #fff;
  padding-top: 200rem;

  @media (max-width: 1100px) {
    padding-top: 200px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    padding: 100px 15px 0;
  }
}

.symbols-subtitle {
  font-size: 24rem;
  font-weight: 600;
  line-height: 33.6rem;
  text-align: center;
  width: 50%;
  margin: 0 auto 100rem;
  opacity: 0.8;

  @media (max-width: 1100px) {
    font-size: 18px;
    line-height: 1.2;
    margin: 30px auto 0;
    width: 90%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.prev {
  height: 1300rem;

  @media (max-width: 1100px) {
    height: 800px;
  }

  @media (max-width: 768px) {
    height: 900px;
  }

  @media (max-width: 1024px) and (min-height: 1000px) {
    display: none;
  }

  @media (max-height: 450px) {
    display: none;
  }

  @media (max-width: 1024px) and (min-height: 1100px) {
    display: block;
  }
}

.slider-section {
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 100;

  @media (max-width: 1100px) {
    bottom: 0;
  }

  @media (max-width: 800px) {
    bottom: 350px;
  }

  @media (max-width: 550px) {
    bottom: -500px;
  }

  @media (max-width: 550px) and (min-width: 500px) and (min-height: 700px) {
    bottom: -800px;
  }
}

.wheel {
  position: absolute;
  top: -500rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300vw;
  height: 100vw;
  max-width: 2000rem;
  max-height: 1000rem;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: bottom;

  @media (max-width: 1100px) {
    max-width: 2000rem;
    max-height: 1000rem;
  }

  @media (max-width: 768px) {
    max-height: 1000rem;
    top: -50px;
  }

  @media (max-width: 550px) {
    top: -480px;
    max-height: 1000rem;
  }

  @media (max-width: 550px) and (min-width: 500px) {
    top: -550px;
  }

  @media (max-width: 550px) and (min-height: 550px) and (max-height: 700px) {
    top: -550px;
  }

  @media (max-width: 340px) {
    top: -650px;
  }

  @media (max-width: 500px) and (min-height: 900px) {
    top: -400px;
  }

  @media (max-width: 991px) and (min-height: 800px) and (min-width: 768px) {
    top: -100px;
  }
}

.wheel__card {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 650rem;
  max-width: 1000rem;
  aspect-ratio: 1 / 1;
  background-color: var(--accent-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15rem;
  padding: 15rem 15rem 0 15rem;

  @media (max-width: 1100px) {
    width: 35%;
    height: 500px;
    max-width: 1000px;
    border-radius: 15px;
    justify-content: space-between;
    padding: 10px 10px 15px 10px;
  }

  @media (max-width: 768px) {
    width: 70%;
    height: 420px;
    min-height: 220px;
    max-width: 1000px;
  }

  @media (max-width: 550px) and (min-height: 750px) {
    min-height: 450px;
  }

  @media (max-width: 500px) and (min-height: 900px) {
    width: 70%;
    min-height: 460px;
  }

  @media (max-width: 1000px) and (min-height: 1100px) {
    width: 40%;
    min-height: 560px;
  }

  @media (max-width: 800px) and (min-height: 1000px) {
    width: 35%;
    min-height: 260px;
  }

  @media (max-width: 550px) and (min-width: 500px) and (min-height: 700px) {
    min-height: 520px;
  }

  p {
    color: var(--text-title-black-color);
    padding: 15rem;
    text-align: center;
    font-size: 20rem;
    font-weight: 500;

    @media (max-width: 1100px) {
      padding: 15px 15px 0 15px;
      font-size: 18px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999;
    object-fit: cover;
    border-radius: 15rem;

    @media (max-width: 1100px) {
      border-radius: 15px;
    }
  }
}

.slide-section {
  .slide-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 100px;

    @media (max-height: 450px) {
      margin-bottom: 400px;
    }

    .slide-card {
      background-color: var(--accent-bg-color);
      width: 30%;
      padding: 10px;
      border-radius: 20px;
      img {
        width: 100%;
        border-radius: 20px;
      }

      p {
        color: var(--text-title-black-color);
        padding-top: 10px;
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
