.draw {
  background-color: var(--black);
  position: relative;
  z-index: 103;

  @media (max-width: 1100px) {
    height: 100%;
  }

  @media (max-width: 1100px) and (min-height: 1350px) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    height: max-content;
  }

  &-bg {
    min-width: 1900rem;
    min-height: 1210rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 768px) {
      min-height: 850px;
      min-width: 100%;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: max-content;
  }

  .decor-draw {
    content: '';
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 40%;
    background-color: #351007;
    filter: blur(80rem);
    transform: translate3d(0, 0, 0);
    z-index: -1;
    opacity: 0;

    @media (max-width: 1100px) {
      height: 70%;
      top: 50%;
      filter: blur(50px);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .icons {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30rem;
      position: relative;
      padding-top: 350rem;

      @media (max-width: 768px) {
        height: unset;
        align-items: flex-start;
        padding-top: 400px;
      }

      @media (max-width: 550px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        grid-auto-rows: minmax(100rem, auto);
        padding-left: 15px;
        padding-right: 15px;
        height: unset;
      }

      @media (max-width: 769px) and (min-height: 1000px) {
        padding-top: 470px;
      }

      @media (max-width: 1150px) and (min-width: 991px) and (min-height: 450px) and (max-height: 1000px) {
        padding-top: 550rem;
      }

      @media (max-width: 991px) and (min-height: 800px) and (min-width: 768px) {
        padding-top: 300px;
      }

      @media (width: 1024px) and (height: 1366px) {
        padding-top: 300px;
      }

      .icons-title {
        position: absolute;
        left: 50%;
        top: 100rem;
        transform: translateX(-50%);
        opacity: 0;
        width: 100%;
        text-align: center;

        h2 {
          margin-bottom: 30rem;
          @media (max-width: 1100px) {
            font-size: 48px;
            top: 50px;
            margin-bottom: 20px;
            margin-top: 50px;
          }

          @media (max-width: 1100px) and (min-height: 1000px) {
            top: 0;
          }

          @media (max-width: 769px) and (min-height: 1000px) {
            top: 350px;
          }

          @media (max-width: 768px) {
            top: 100px;
          }

          @media (max-width: 769px) and (min-height: 1020px) and (min-width: 550px) {
            top: 370px;
          }
        }

        p {
          font-size: 24rem;
          width: 60%;
          opacity: 0.8;
          margin: 0 auto;
          font-weight: 400;

          @media (max-width: 1100px) {
            font-size: 16px;
            width: 90%;
          }
        }
      }
    }

    .icons-block {
      width: 200rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      opacity: 0;
      margin-bottom: 30rem;
      height: 150rem;

      @media (max-width: 1100px) {
        width: 150px;
        margin-bottom: 0;
        height: auto;
      }

      @media (max-width: 768px) {
        width: 100px;
      }

      @media (max-width: 550px) {
        text-align: center;
        width: auto;
      }

      &:nth-child(2) {
        img {
          opacity: 0 !important;

          @media (max-width: 1100px) and (min-width: 991px) {
            opacity: 1 !important;
          }
          @media (max-width: 991px) and (min-width: 767px) and (min-height: 1000px) {
            opacity: 1 !important;
          }
        }
      }

      img {
        margin-bottom: 10rem;
        width: 102rem;
        height: 102rem;

        @media (max-width: 550px) {
          width: 350rem;
          height: 350rem;
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 12rem;
        font-weight: 500;
        text-align: center;

        @media (max-width: 1100px) {
          font-size: 12px;
        }

        @media (max-width: 768px) {
          font-size: 9px;
          line-height: 1.3;
        }

        @media (max-width: 400px) {
          font-size: 7px;
          line-height: 1.2;
        }
      }
    }
  }
}

.svg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1210rem;

  @media (max-width: 768px) {
    min-height: 850px;
    width: 350vw;
    left: -125%;
  }

  .svg-icon {
    width: 100%;
    height: 100%;
    overflow: unset;
    object-fit: cover;

    g {
      .st0 {
        fill: none;
      }
    }

    path {
      stroke-width: 35;
    }

    .path-wrapper {
      path {
        fill: none;
        stroke-dasharray: 0px 1500px;

        @media (min-height: 1000px) and (max-width: 1100px) {
          stroke-dasharray: 0 0;
        }
      }
    }
  }
}

.path2 {
  transform: translateX(1.24%);

  @media (max-width: 1100px) {
    transform: translateX(17.5px);
  }
}
.path6 {
  transform: translateX(-1.24%);

  @media (max-width: 1100px) {
    transform: translateX(-17.5px);
  }
}
